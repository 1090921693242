import React, {useState, useEffect} from 'react';
import ReactPDF,{ Page, Text, Image, Font, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import formatMoney from 'util/FormatMoney';
import moment from 'moment';

Font.register({
  family: "Roboto",
  fonts: [
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf", fontStyle: 'italic'},
    {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bolditalic-webfont.ttf", fontStyle: 'italic'},
  ]
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    fontFamily: 'Roboto',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 600
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 600,
    backgroundColor: '#ED1C24',
    marginTop: 10,
  },
  
  image: {
    // marginVertical: 15,
    // marginHorizontal: 100,
    width: 200,
    height: 60
  },
  // pageNumber: {
  //   position: 'absolute',
  //   fontSize: 12,
  //   bottom: 30,
  //   left: 0,
  //   right: 0,
  //   textAlign: 'center',
  //   color: 'grey',
  // },
  main: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  width4: {
    width: '4%'
  },
  width5: {
    width: '5%'
  },
  width8: {
    width: '8%'
  },
  width10: {
    width: '10%'
  },
  width11: {
    width: '11%'
  },
  width12: {
    width: '12%'
  },
  width13: {
    width: '13%'
  },
  width15: {
    width: '15%'
  },
  width18: {
    width: '18%'
  },
  width20: {
    width: '20%'
  },
  width25: {
    width: '25%'
  },
  width26: {
    width: '26%'
  },
  width28: {
    width: '28%'
  },
  width29: {
    width: '29%'
  },
  width30: {
    width: '30%'
  },
  width33: {
    width: '33%'
  },
  width35: {
    width: '35%'
  },
  width36: {
    width: '36%'
  },
  width38: {
    width: '38%'
  },
  width40: {
    width: '40%'
  },
  width50: {
    width: '50%'
  },
  width60: {
    width: '60%'
  },
  width67: {
    width: '67%'
  },
  width72: {
    width: '72%'
  },
  width75: {
    width: '75%'
  },
  width78: {
    width: '78%'
  },
  width95: {
    width: '95%'
  },
  width96: {
    width: '96%'
  },
  width100: {
    width: '100%'
  },
  headerAddress: {
    width: '60%',
    fontSize: 12
  },
  height1: {
    height: 30
  },
  headerRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    textTransform: 'none'
  },
  logo: {
    width: 150,
    height: 100
  },
  font7: {fontSize: 7},
  font10: {fontSize: 10},
  font12: {fontSize: 12},
  font14: {fontSize: 14},
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  footer: {
    // position: 'absolute',
    fontSize: 10,
    // bottom: 30,
    // left: 0,
    // right: 0,
    textAlign: 'center',
    paddingVertical: 10
  },
  footerChild: {
    paddingHorizontal: 20
  },
  colorRed: {color: 'red'},
  colorWhite: {color: 'white'},
  colorGray: {color: 'gray'},
  textCenter: {textAlign: 'center', paddingHorizontal: 2},
  border: {border: 1, borderBottomStyle: 'dashed'},
  borderR: {borderRight: 1, borderRightStyle: 'dashed'},
  borderTRB: {borderTop: 1, borderRight: 1, borderBottom: 1},
  borderRBL: {borderRight: 1, borderBottom: 1, borderLeft: 1, borderBottomStyle: 'dashed'},
  borderRBL2: {borderRight: 1, borderBottom: 1, borderLeft: 1},
  table: {fontSize: 10},
  headerTable: {
    flexDirection: 'row',
    // justifyContent: 'flex-end',
    backgroundColor: '#ED1C24',
  },
  rowTable: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  categoryGroup: {
    backgroundColor: '#A6A6A6'
  },
  cell: {paddingHorizontal: 5, paddingVertical: 3},
  cellTotal: {paddingHorizontal: 5, paddingVertical: 3, fontWeight: 600},
  totalFooter: {backgroundColor: '#2F74B5'},
  textLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  textRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  textItalic: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
  },
  fontWeight300: {
    fontWeight: 300
  },
  customer: {
    paddingTop: 50
  },
  bgYellow: {backgroundColor: 'yellow'}
});

const arrNumber = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
let cateIndex = 0;
let cateIndex2 = 0;
let sumTotal = 0;
// Create Document Component
const MyDocument = ({isMobile, dataPdf}) => {
  
  const dataTable = dataPdf? dataPdf.itemsPdf : [];
  const infor = dataPdf? dataPdf.infor : {};
  // const ccmLogo = { uri: `${window.location.origin}/assets/images/logo_ccm.png`, method: 'GET', headers: {"Cache-Control": "no-cache"}, body: '' };
  return (
    <PDFViewer width='100%' height='100%'>
      <Document>
        {/*<Page wrap={!isMobile} style={styles.body} size={isMobile ? [window.innerWidth * 0.99, window.innerHeight] : 'A4'}>*/}
        <Page orientation='landscape' wrap={!isMobile} style={styles.body}>
          <View style={styles.main}>
            <View style={styles.width40}>
              <Image
                style={styles.image}
                src={`${window.location.origin}/assets/images/logo_ccm.png`}
                />
            </View>
            <View style={styles.headerAddress}>
              <View style={styles.headerRight}>
                <Text>CCMFast Thủ Đức</Text>
              </View>
              <View style={styles.headerRight}>
                <Text>520 QL13, Hiệp Bình Phước</Text>
              </View>
              <View style={styles.headerRight}>
                <Text>Thủ Đức, Thành phố Hồ Chí Minh</Text>
              </View>
              <View style={styles.headerRight}>
                <Text>Tel: 0396300888</Text>
              </View>
            </View>
          </View>
          
          <View style={styles.title}>
            <Text>
              HÓA ĐƠN BÁN HÀNG
            </Text>
          </View>
          
          <View style={{...styles.content, paddingTop: 10}}>
            <View style={styles.width25}>
              <Text>Ngày: {infor.date ? moment(infor.date).format('DD/MM/YYYY') : ''}</Text>
            </View>
            <View style={styles.width35}>
              <Text>Chủ xe: {infor.customer_name || ''}</Text>
            </View>
            <View style={styles.width20}>
              <Text>SĐT: {infor.phone_number || ''}</Text>
            </View>
            <View style={styles.width20}>
              <Text>Biển số: {infor.tag_name || ''}</Text>
            </View>
          </View>
          <View style={styles.content}>
            <View style={styles.width25}>
              <Text>Hãng: {infor.car_name || ''}</Text>
            </View>
            <View style={styles.width35}>
              <Text>Model: {infor.model_name || ''}</Text>
            </View>
            <View style={styles.width20}>
              <Text>Đời: {infor.year_name || ''}</Text>
            </View>
            <View style={styles.width20}>
              <Text>Động cơ: {infor.engine_name || ''}</Text>
            </View>
          </View>
          <View style={styles.content}>
            <View style={styles.width25}>
              <Text>Hộp số: {infor.gear_name || ''}</Text>
            </View>
            <View style={styles.width35}>
              <Text>Dung tích: {infor.capacity_name || ''}</Text>
            </View>
            <View style={styles.width20}></View>
            <View style={styles.width20}></View>
          </View>
          {/*<View style={{...styles.content, ...styles.uppercase, paddingVertical: 10, ...styles.font12 }}>
            <Text>* Lưu ý: Bảng báo giá chỉ có hiệu lực trong vòng 15 ngày kể từ ngày báo giá. Quý khách vui lòng đặt cọc trước 50% tổng giá trị phụ tùng để bên em tiến hành đặt hàng ạ và trong quá trình thay thế phụ tùng nếu có phát sinh gì thêm sẽ báo sau.</Text>
          </View>*/}
          
          <View style={{...styles.table, paddingTop: 10}}>
            {dataTable.length && dataTable.map((item, index) => {
              const children = item.children || [];
							let stt = 1;
							let total = 0;
							let don_gia = 0;
							let giam_gia = 0;
							let vat = 0;
            
              return (
                <>
                  { item.status &&
                    <>
                      {cateIndex === 0 && 
                        <View fixed={true} style={{...styles.headerTable, ...styles.border}}>
                          <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                            <Text>STT</Text>
                          </View>
                          { Number(infor.vat) == 0
                            ?
                            <View style={{...styles.width33, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>Tên hàng hóa dịch vụ và mã hàng</Text>
                            </View>
                            :
                            <View style={{...styles.width29, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>Tên hàng hóa dịch vụ và mã hàng</Text>
                            </View>
                          }
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                            <Text>Đơn giá</Text>
                          </View>
                          <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                            <Text>SL</Text>
                          </View>
                          <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                            <Text>ĐVT</Text>
                          </View>
                          <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                            <Text>Thương hiệu</Text>
                          </View>
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                            <Text>Giảm giá</Text>
                          </View>
                          { Number(infor.vat) != 0 &&
                            <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>VAT</Text>
                            </View>
                          }
                          <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                            <Text>Thành tiền</Text>
                          </View>
                          <View style={{...styles.width12, ...styles.textCenter, flexDirection: 'column', justifyContent: 'center'}}>
                            <Text>Bảo hành</Text>
                          </View>
                        </View>
                      }
                      
                      <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL, ...styles.categoryGroup, ...styles.textItalic}}>
                        <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                          <Text>{arrNumber[cateIndex++]}</Text>
                        </View>
                        <View style={{...styles.width96, ...styles.textCenter, ...styles.cell}}>
                          <Text>{item.product_name}</Text>
                        </View>
                      </View>
                      
                      {	children.length > 0 && children.map((childItem, childIndex) => {
                        if(childItem.status == 1 && childItem.quantity > 0) {
    											total = total + parseFloat(childItem.total_amount);
    											sumTotal = sumTotal + parseFloat(childItem.total_amount);
    											  
    										// 	if(Number(childItem.discount) > 0 && Number(childItem.total_amount) > 0) {
    										// 	  don_gia = Number(Number(childItem.total_amount) * 100 / (100 - Number(childItem.discount)));
    											  
    										// 	  if(parseFloat(childItem.quantity) > 0) {
    										// 	    don_gia = parseInt(Number(don_gia / parseFloat(childItem.quantity)) / 100) * 100;
    										// 	  }
    										// 	} else {
    										// 	  if(Number(childItem.total_amount) > 0 && parseFloat(childItem.quantity) > 0)
      								// 			  don_gia = Math.round(parseFloat(parseFloat(childItem.total_amount) / parseFloat(childItem.quantity)) / 100) * 100;
      								// 			else
      								// 			  don_gia = 0;
    										// 	}
    										
    										  if(Number(childItem.discount) > 0) {
  										      giam_gia = Number(childItem.discount);
  										    } else {
  										      giam_gia = 0;
  										    }
  										    
  										    if(Number(childItem.vat) > 0) {
  										      vat = Number(childItem.vat);
  										    } else {
  										      vat = 0;
  										    }
    										
    										  if(Number(childItem.total_amount) > 0) {
    										    if(giam_gia > 0)
    										      don_gia = Math.round(Number(childItem.total_amount) / (((100 + vat) / 100) * ((100 - giam_gia) / 100) * parseFloat(childItem.quantity)));
    										    else
    										      don_gia = Math.round(Number(childItem.total_amount) / (((100 + vat) / 100) * parseFloat(childItem.quantity)));
    										  } else {
    										    don_gia = 0;
    										  }
    
                          if(childItem.highlight == 1) {
      											return (
    											    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL, ...styles.bgYellow}}>
                                <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{stt++}</Text>
                                </View>
                                { Number(infor.vat) == 0
                                  ?
                                  <View style={{...styles.width33, ...styles.textLeft, ...styles.borderR, ...styles.cell, flexDirection: 'column'}}>
                                    <Text>{childItem.product_name}</Text>
                                    <Text style={{...styles.textItalic, ...styles.font7, ...styles.colorGray}}>{childItem.description}</Text>
                                  </View>
                                  :
                                  <View style={{...styles.width29, ...styles.textLeft, ...styles.borderR, ...styles.cell, flexDirection: 'column'}}>
                                    <Text>{childItem.product_name}</Text>
                                    <Text style={{...styles.textItalic, ...styles.font7, ...styles.colorGray}}>{childItem.description}</Text>
                                  </View>
                                }
                                <View style={{...styles.width10, ...styles.textRight, ...styles.borderR, ...styles.cell}}>
                                  {/*<Text>{childItem.price ? formatMoney(childItem.price) : 0}</Text>*/}
                                  <Text>{childItem.discount == 100 && don_gia == 0 ? formatMoney(childItem.price) : formatMoney(don_gia)}</Text>
                                </View>
                                <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.quantity ? formatMoney(childItem.quantity) : 0}</Text>
                                </View>
                                <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.unit_name}</Text>
                                </View>
                                <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell, ...styles.uppercase}}>
                                  <Text>{childItem.trademark}</Text>
                                </View>
                                <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.discount ? formatMoney(childItem.discount) + ' %' : 'Không'}</Text>
                                </View>
                                { Number(infor.vat) != 0 &&
                                  <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                    <Text>{childItem.vat ? formatMoney(childItem.vat) : 0}</Text>
                                  </View>
                                }
                                <View style={{...styles.width10, ...styles.textRight, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.total_amount ? formatMoney(childItem.total_amount) : 0}</Text>
                                </View>
                                <View style={{...styles.width12, ...styles.textCenter, ...styles.cell}}>
                                  <Text>{childItem.guarantee}</Text>
                                </View>
                              </View>
      											);
      										} else {
      										  return (
    											    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                                <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{stt++}</Text>
                                </View>
                                { Number(infor.vat) == 0
                                  ?
                                  <View style={{...styles.width33, ...styles.textLeft, ...styles.borderR, ...styles.cell, flexDirection: 'column'}}>
                                    <Text>{childItem.product_name}</Text>
                                    <Text style={{...styles.textItalic, ...styles.font7, ...styles.colorGray}}>{childItem.description}</Text>
                                  </View>
                                  :
                                  <View style={{...styles.width29, ...styles.textLeft, ...styles.borderR, ...styles.cell, flexDirection: 'column'}}>
                                    <Text>{childItem.product_name}</Text>
                                    <Text style={{...styles.textItalic, ...styles.font7, ...styles.colorGray}}>{childItem.description}</Text>
                                  </View>
                                }
                                <View style={{...styles.width10, ...styles.textRight, ...styles.borderR, ...styles.cell}}>
                                  {/*<Text>{childItem.price ? formatMoney(childItem.price) : 0}</Text>*/}
                                  <Text>{childItem.discount == 100 && don_gia == 0 ? formatMoney(childItem.price) : formatMoney(don_gia)}</Text>
                                </View>
                                <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.quantity ? formatMoney(childItem.quantity) : 0}</Text>
                                </View>
                                <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.unit_name}</Text>
                                </View>
                                <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell, ...styles.uppercase}}>
                                  <Text>{childItem.trademark}</Text>
                                </View>
                                <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.discount ? formatMoney(childItem.discount) + ' %' : 'Không'}</Text>
                                </View>
                                { Number(infor.vat) != 0 &&
                                  <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                    <Text>{childItem.vat ? formatMoney(childItem.vat) : 0}</Text>
                                  </View>
                                }
                                <View style={{...styles.width10, ...styles.textRight, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.total_amount ? formatMoney(childItem.total_amount) : 0}</Text>
                                </View>
                                <View style={{...styles.width12, ...styles.textCenter, ...styles.cell}}>
                                  <Text>{childItem.guarantee}</Text>
                                </View>
                              </View>
      											);
      										}
    										}
                      })}
                    </>
                  }
                  
                  { stt > 1 &&
                    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL2, ...styles.colorRed,...styles.textItalic}}>
                      <View style={{...styles.width78, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>Tổng cộng:</Text>
                      </View>
                      <View style={{...styles.width10, ...styles.cell, ...styles.textRight}}>
                        <Text>{total ? formatMoney(total) : 0}</Text>
                      </View>
                      <View style={{...styles.width12, ...styles.cell}}></View>
                    </View>
									}
                </>
              );
            })}
            
            { sumTotal > 0 &&
              <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL2, ...styles.totalFooter, ...styles.colorWhite, ...styles.textItalic}}>
                <View style={{...styles.width78, ...styles.textCenter, ...styles.borderR, ...styles.cellTotal}}>
                  <Text>Tổng cộng các hạng mục cần thay thế:</Text>
                </View>
                <View style={{...styles.width10, ...styles.cellTotal, ...styles.textRight}}>
                  <Text>{sumTotal ? formatMoney(sumTotal) : 0}</Text>
                </View>
                <View style={{...styles.width12, ...styles.cell}}></View>
              </View>
					  }
          </View>
        
          <View style={{...styles.table}}>
            {dataTable.length && dataTable.map((item, index) => {
              const children = item.children || [];
							let stt2 = 1;
							let total2 = 0;
							let don_gia2 = 0;
							let giam_gia2 = 0;
							let vat2 = 0;
            
              return (
                <View>
                  { item.status == 0 &&
                    <>
                      {(cateIndex2 === 0 && children.length > 0) &&
                        <View>
                          <View wrap={false} style={{...styles.content, ...styles.uppercase, paddingVertical: 10, ...styles.font12 }}>
                            <Text>* PHẦN DƯỚI ĐÂY LÀ CÁC HẠNG MỤC MANG TÍNH CHẤT CẢNH BÁO THAY THẾ HOẶC THAM KHẢO THÊM</Text>
                          </View>
                          
                          <View wrap={false} style={{...styles.headerTable, ...styles.border}}>
                            <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>STT</Text>
                            </View>
                            { Number(infor.vat) == 0
                              ?
                              <View style={{...styles.width33, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                                <Text>Tên hàng hóa dịch vụ và mã hàng</Text>
                              </View>
                              :
                              <View style={{...styles.width29, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                                <Text>Tên hàng hóa dịch vụ và mã hàng</Text>
                              </View>
                            }
                            <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>Đơn giá</Text>
                            </View>
                            <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>SL</Text>
                            </View>
                            <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>ĐVT</Text>
                            </View>
                            <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>Thương hiệu</Text>
                            </View>
                            <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>Giảm giá</Text>
                            </View>
                            { Number(infor.vat) != 0 &&
                              <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                                <Text>VAT</Text>
                              </View>
                            }
                            <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>Thành tiền</Text>
                            </View>
                            <View style={{...styles.width12, ...styles.textCenter, flexDirection: 'column', justifyContent: 'center'}}>
                              <Text>Bảo hành</Text>
                            </View>
                          </View>
                        </View>
                      }
                      
                      <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL, ...styles.categoryGroup, ...styles.textItalic}}>
                        <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                          <Text>{arrNumber[cateIndex2++]}</Text>
                        </View>
                        <View style={{...styles.width96, ...styles.textCenter, ...styles.cell}}>
                          <Text>{item.product_name}</Text>
                        </View>
                      </View>
                      
                      {	children.length > 0 && children.map((childItem, childIndex) => {
                        if(childItem.status == 1 && childItem.quantity > 0) {
    											total2 = total2 + parseFloat(childItem.total_amount);
    											  
    										// 	if(Number(childItem.discount) > 0 && Number(childItem.total_amount) > 0) {
    										// 	  don_gia2 = Number(Number(childItem.total_amount) * 100 / (100 - Number(childItem.discount)));
    											  
    										// 	  if(parseFloat(childItem.quantity) > 0) {
    										// 	    don_gia2 = parseInt(Number(don_gia2 / parseFloat(childItem.quantity)) / 100) * 100;
    										// 	  }
    										// 	} else {
    										// 	  if(Number(childItem.discount) == 0 && Number(childItem.total_amount) > 0) {
    										// 	    don_gia2 = childItem.unit_price;
    										// 	  } else {
      								// 			  if(Number(childItem.total_amount) > 0 && parseFloat(childItem.quantity) > 0)
        						// 					  don_gia2 = Math.round(parseFloat(parseFloat(childItem.total_amount) / parseFloat(childItem.quantity)) / 100) * 100;
        						// 					else
        						// 					  don_gia2 = 0;
    										// 	  }
    										// 	}
    										
    										  if(Number(childItem.discount) > 0) {
  										      giam_gia2 = Number(childItem.digscount);
  										    } else {
  										      giam_gia2 = 0;
  										    }
  										    
  										    if(Number(childItem.vat) > 0) {
  										      vat2 = Number(childItem.vat);
  										    } else {
  										      vat2 = 0;
  										    }
    										
    										  if(Number(childItem.total_amount) > 0) {
    										    if(giam_gia2 > 0)
    										      don_gia2 = Math.round(Number(childItem.total_amount) / (((100 + vat2) / 100) * ((100 - giam_gia2) / 100) * parseFloat(childItem.quantity)));
    										    else
    										      don_gia2 = Math.round(Number(childItem.total_amount) / (((100 + vat2) / 100) * parseFloat(childItem.quantity)));
    										  } else {
    										    don_gia2 = 0;
    										  }
    
                          if(childItem.highlight == 1) {
      											return (
      											  <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL, ...styles.bgYellow}}>
                                <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{stt2++}</Text>
                                </View>
                                { Number(infor.vat) == 0
                                  ?
                                  <View style={{...styles.width33, ...styles.textLeft, ...styles.borderR, ...styles.cell, flexDirection: 'column'}}>
                                    <Text>{childItem.product_name}</Text>
                                    <Text style={{...styles.textItalic, ...styles.font7, ...styles.colorGray}}>{childItem.description}</Text>
                                  </View>
                                  :
                                  <View style={{...styles.width29, ...styles.textLeft, ...styles.borderR, ...styles.cell, flexDirection: 'column'}}>
                                    <Text>{childItem.product_name}</Text>
                                    <Text style={{...styles.textItalic, ...styles.font7, ...styles.colorGray}}>{childItem.description}</Text>
                                  </View>
                                }
                                <View style={{...styles.width10, ...styles.textRight, ...styles.borderR, ...styles.cell}}>
                                  {/*<Text>{childItem.price ? formatMoney(childItem.price) : 0}</Text>*/}
                                  <Text>{childItem.discount == 100 && don_gia2 == 0 ? formatMoney(childItem.price) : formatMoney(don_gia2)}</Text>
                                </View>
                                <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.quantity ? formatMoney(childItem.quantity) : 0}</Text>
                                </View>
                                <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.unit_name}</Text>
                                </View>
                                <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell, ...styles.uppercase}}>
                                  <Text>{childItem.trademark}</Text>
                                </View>
                                <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.discount ? formatMoney(childItem.discount) + ' %' : 'Không'}</Text>
                                </View>
                                { Number(infor.vat) != 0 &&
                                  <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                    <Text>{childItem.vat ? formatMoney(childItem.vat) : 0}</Text>
                                  </View>
                                }
                                <View style={{...styles.width10, ...styles.textRight, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.total_amount ? formatMoney(childItem.total_amount) : 0}</Text>
                                </View>
                                <View style={{...styles.width12, ...styles.textCenter, ...styles.cell}}>
                                  <Text>{childItem.guarantee}</Text>
                                </View>
                              </View>
      											);
                          } else {
                            return (
      											  <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL}}>
                                <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{stt2++}</Text>
                                </View>
                                { Number(infor.vat) == 0
                                  ?
                                  <View style={{...styles.width33, ...styles.textLeft, ...styles.borderR, ...styles.cell, flexDirection: 'column'}}>
                                    <Text>{childItem.product_name}</Text>
                                    <Text style={{...styles.textItalic, ...styles.font7, ...styles.colorGray}}>{childItem.description}</Text>
                                  </View>
                                  :
                                  <View style={{...styles.width29, ...styles.textLeft, ...styles.borderR, ...styles.cell, flexDirection: 'column'}}>
                                    <Text>{childItem.product_name}</Text>
                                    <Text style={{...styles.textItalic, ...styles.font7, ...styles.colorGray}}>{childItem.description}</Text>
                                  </View>
                                }
                                <View style={{...styles.width10, ...styles.textRight, ...styles.borderR, ...styles.cell}}>
                                  {/*<Text>{childItem.price ? formatMoney(childItem.price) : 0}</Text>*/}
                                  <Text>{childItem.discount == 100 && don_gia2 == 0 ? formatMoney(childItem.price) : formatMoney(don_gia2)}</Text>
                                </View>
                                <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.quantity ? formatMoney(childItem.quantity) : 0}</Text>
                                </View>
                                <View style={{...styles.width5, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.unit_name}</Text>
                                </View>
                                <View style={{...styles.width12, ...styles.textCenter, ...styles.borderR, ...styles.cell, ...styles.uppercase}}>
                                  <Text>{childItem.trademark}</Text>
                                </View>
                                <View style={{...styles.width10, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.discount ? formatMoney(childItem.discount) + ' %' : 'Không'}</Text>
                                </View>
                                { Number(infor.vat) != 0 &&
                                  <View style={{...styles.width4, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                                    <Text>{childItem.vat ? formatMoney(childItem.vat) : 0}</Text>
                                  </View>
                                }
                                <View style={{...styles.width10, ...styles.textRight, ...styles.borderR, ...styles.cell}}>
                                  <Text>{childItem.total_amount ? formatMoney(childItem.total_amount) : 0}</Text>
                                </View>
                                <View style={{...styles.width12, ...styles.textCenter, ...styles.cell}}>
                                  <Text>{childItem.guarantee}</Text>
                                </View>
                              </View>
      											);
                          }
    										}
                      })}
                    </>
                  }
                  
                  { stt2 > 1 &&
                    <View wrap={false} style={{...styles.rowTable, ...styles.borderRBL2, ...styles.colorRed,...styles.textItalic}}>
                      <View style={{...styles.width78, ...styles.textCenter, ...styles.borderR, ...styles.cell}}>
                        <Text>Tổng cộng:</Text>
                      </View>
                      <View style={{...styles.width10, ...styles.cell, ...styles.textRight}}>
                        <Text>{total2 ? formatMoney(total2) : 0}</Text>
                      </View>
                      <View style={{...styles.width12, ...styles.cell}}></View>
                    </View>
									}
                </View>
              );
            })}
          </View>
          
          <View style={styles.footer}>
            { Number(infor.vat) == 0 
              ?
              <Text>Giá trên chưa bao gồm VAT</Text>
              :
              <Text>Giá trên đã bao gồm VAT</Text>
            }
            {/*<Text>Đây là báo giá tạm tính, các phát sinh trong lúc thay thế sẽ báo quý khách sau</Text>
            <Text style={styles.colorRed}>Mọi chi tiết thắc mắc quý khách vui lòng liên hệ em: {infor.last_name} {infor.phone_saler}</Text>*/}
          </View>
          
          <View style={styles.main}>
            <View style={{...styles.width60, fontSize: 13}}>
              <Text>&nbsp;</Text><Text>&nbsp;</Text><Text>&nbsp;</Text><Text>&nbsp;</Text>
              <Text style={{fontSize: 8, textTransform: '',}}>{moment().format('DD/MM/YYYY HH:mm')} {infor.paid_status?'final':''}</Text>
            </View>
            
            <View style={{...styles.width40, ...styles.textCenter, ...styles.font10}}>
              <Text>Xác nhận của khách hàng</Text>
              <Text style={{...styles.customer}}>{infor.customer_name}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>);
};

export default MyDocument;