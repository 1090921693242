import store from 'appRedux/store';
import server from 'services/server';
import { API_URI } from 'constants/index';

const DETAIL_PAGE = '/project';

const _ = {
  name: 'orderDetail',
  state: {
    item: {},
    created: {},
    listUser: [],
    listUserComplete: [],
    conditionCloseCard: false,
    listReceipt: []
  }, // initial state
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        item: payload,
      };
    },
    setDataCreated(state, payload) {
      return {
        ...state,
        created: payload,
      };
    },
    setListUser(state, payload) {
      return {
        ...state,
        listUser: payload,
      };
    },
    setStatus(state, payload) {
      return {
        ...state,
        status: payload,
      };
    },
    setConditionCloseCard(state, payload) {
      return {
        ...state,
        conditionCloseCard: payload,
      };
    },
    setDataUserComplete(state, payload) {
      return {
        ...state,
        listUserComplete: payload,
      };
    },
    setDataReceipt(state, payload) {
      return {
        ...state,
        listReceipt: payload
      };
    },
  },
  effects: {
    async get(getParams) {
      const { data } = await server.get(`${API_URI}/orders/${getParams.id}`);
      this.setData(data);
    },
    async create(saveParams) {
      try {
        const { data } = await server.post(`${API_URI}/orders/createDetail`, saveParams);
        this.setDataCreated(data);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async update(saveParams) {
      try {
        await server.post(`${API_URI}/orders/updateDetail/${saveParams.id}`, saveParams.params);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async updateOrder(saveParams) {
      try {
        await server.post(`${API_URI}/orders/updateOrder`, saveParams);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async updateQuoted(saveParams) {
      try {
        await server.post(`${API_URI}/orders/updateQuoted`, saveParams);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async cancelQuoted(saveParams) {
      try {
        await server.post(`${API_URI}/orders/cancelQuoted`, saveParams);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async updatePaidStatus(saveParams) {
      try {
        await server.post(`${API_URI}/orders/updatePaidStatus`, saveParams);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async createInvoice(saveParams) {
      try {
        await server.post(`${API_URI}/invoices`, saveParams);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async cancelAccept(params) {
      try {
        await server.get(`${API_URI}/orders/cancelAccept/${params.id}`);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async getListUser(params) {
      const { data } = await server.post(`${API_URI}/users/getListUser`, params);
      this.setListUser(data);
    },
    async changeUser(params) {
      try {
        await server.post(`${API_URI}/orders/changeUser`, params);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async checkConditionCloseCard(params) {
      const { data } = await server.post(`${API_URI}/orders/checkConditionCloseCard`, params);
      this.setConditionCloseCard(data);
    },
    async unlockOrder(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/unlockOrder`, params);
        return ['success'];
      } catch(e) {
        console.log(e);
      }
    },
    async requestComplete(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/requestComplete`, params);
        return ['success'];
      } catch(e) {
        console.log(e);
      }
    },
    async getUserComplete(params) {
      const { data } = await server.post(`${API_URI}/users/getUserComplete`, params);
      this.setDataUserComplete(data);
    },
    async requestDebt(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/requestDebt`, params);
        return ['success'];
      } catch(e) {
        console.log(e);
      }
    },
    async pauseOrder(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/pauseOrder`, params);
        return ['success'];
      } catch(e) {
        console.log(e);
      }
    },
    async getReceiptByOrderId(params) {
      const { data } = await server.get(`${API_URI}/receipts/getByOrderId/${params.order_id}`);
      this.setDataReceipt(data);
    },
    async updateTotalMoney(params) {
      try {
        await server.post(`${API_URI}/orders/updateTotalMoney`, params);
        return ['success'];
        
      } catch({response : { data: { message }, data}}) {
        if(typeof(message) !== 'undefined') {
          return [message];
        } else {
          return [JSON.stringify(data)];
        }
      }
    },
    async unRequestComplete(params) {
      try {
        const { data } = await server.post(`${API_URI}/orders/unRequestComplete`, params);
        return ['success'];
      } catch(e) {
        console.log(e);
      }
    },
  },
};

store.addModel(_);
